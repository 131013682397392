import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { useColor, useTheme } from "./state";
import { Suspense, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Router } from "./routes";
import OvelShapeLoading from "./components/Loader/Ovel";

const queryClient = new QueryClient();

// 4️⃣ RouterProvider added
export default function App() {
  const { theme } = useTheme(useShallow((state) => ({ theme: state.theme })));
  const { color } = useColor(useShallow((state) => ({ color: state.color })));
  useEffect(() => {
    let containDark = document.body.classList.contains("dark");
    let check = theme === "light" && !containDark;
    if (!check) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    document.body.classList.add(color);
  }, [theme, color]);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <div className="min-h-screen grid place-items-center">
            <OvelShapeLoading />
          </div>
        }
      >
        <RouterProvider router={Router} />
      </Suspense>
      <div className="relative">
        <Toaster
          containerStyle={{
            zIndex: 999999,
          }}
          toastOptions={{
            position: "bottom-right",
            success: {
              style: {
                background: "#22c55e",
                color: "white",
              },
              iconTheme: {
                primary: "white",
                secondary: "#22c55e",
              },
            },
            error: {
              style: {
                background: "#ef4444",
                color: "white",
              },
              iconTheme: {
                primary: "white",
                secondary: "#ef4444",
              },
            },
          }}
          gutter={15}
        />
      </div>
    </QueryClientProvider>
  );
}
