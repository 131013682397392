import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { encryptStorage } from "./helpers";

// path
export const DashboardRoutes = { pathname: "/", name: "Beranda" };
export const LoginRoutes = { pathname: "/login", name: "Login" };
export const ProfileRoutes = { pathname: "/profile", name: "Profil" };
export const ApplicationsRoutes = {
  pathname: "/apps",
  name: "Aplikasi",
  permission: "view_apps",
};
export const PermissionsRoutes = {
  pathname: "/permissions",
  name: "Izin Aplikasi",
  permission: "view_permissions",
};
export const PermissionsCreateRoutes = {
  pathname: `${PermissionsRoutes.pathname}/create`,
  name: "Tambah Izin Aplikasi",
  permission: "add_permission",
};
export const PermissionsUpdateRoutes = {
  pathname: `${PermissionsRoutes.pathname}/update`,
  name: "Perbarui Izin Aplikasi",
  permission: "edit_permission",
};
export const RolesRoutes = {
  pathname: "/roles",
  name: "Hak Akses",
  permission: "view_roles",
};
export const RolesCreateRoutes = {
  pathname: `${RolesRoutes.pathname}/create`,
  name: "Tambah Hak Akses",
  permission: "add_role",
};
export const RolesUpdateRoutes = {
  pathname: `${RolesRoutes.pathname}/update`,
  name: "Perbarui Hak Akses",
  permission: "edit_role",
};
export const UsersRoutes = {
  pathname: "/users",
  name: "Pengguna",
  permission: "view_users",
};
export const UsersCreateRoutes = {
  pathname: `${UsersRoutes.pathname}/create`,
  name: "Tambah Pengguna",
  permission: "add_user",
};
export const UsersUpdateRoutes = {
  pathname: `${UsersRoutes.pathname}/update`,
  name: "Perbarui",
  permission: "edit_user",
};
export const CompaniesRoutes = { pathname: "/companies", name: "Perusahaan" };

export const sideMenuRoutes = [
  DashboardRoutes,
  ApplicationsRoutes,
  PermissionsRoutes,
  RolesRoutes,
  UsersRoutes,
];

// component
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Applications = lazy(() => import("./pages/Applications"));
const Permissions = lazy(() => import("./pages/Permissions"));
const CreatePermission = lazy(() =>
  import("./pages/Permissions/CreatePermission")
);
const UpdatePermission = lazy(() =>
  import("./pages/Permissions/UpdatePermission")
);
const Roles = lazy(() => import("./pages/Roles"));
const Users = lazy(() => import("./pages/Users"));
const Profile = lazy(() => import("./pages/Profile"));
const Login = lazy(() => import("./pages/Login"));
const LoginPage = lazy(() => import("./pages/signin"));
const Layout = lazy(() => import("./components/Layout"));
const FormRole = lazy(() => import("./pages/Roles/form"));
const Companies = lazy(() => import("./pages/Companies"));
const FormUser = lazy(() => import("./pages/Users/form/form"));
const Unauthorized = lazy(() => import("./pages/401"));
const AuthUser = lazy(() => import("./AuthUser"));

const Authenticated = ({ component }) => {
  const auth = Cookies.get("sso_refresh_token");
  if (!auth) {
    encryptStorage.removeItem("ssoUser");
    encryptStorage.removeItem("ssoRoles");
    return <Navigate to="/login" />;
  }

  return component;
};

const Authorized = ({ component, permission }) => {
  const permissions =
    encryptStorage.getItem("ssoRoles")?.find((data) => data?.appCode === "sso")
      ?.permissions || [];
  const { permissions: permissions2, is_custom } =
    encryptStorage.getItem("ssoProfile");
  const auth = parseInt(is_custom || 0)
    ? permissions2?.includes(permission)
    : permissions?.includes(permission);
  if (!auth) {
    return <Navigate to="/401" />;
  }
  return component;
};

const Router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Authenticated component={<Layout />} />,
      children: [
        { path: DashboardRoutes.pathname, element: <Dashboard /> },
        {
          path: ApplicationsRoutes.pathname,
          element: (
            <Authorized component={<Applications />} permission={"view_apps"} />
          ),
        },
        {
          path: PermissionsRoutes.pathname,
          element: (
            <Authorized
              component={<Permissions />}
              permission={"view_permissions"}
            />
          ),
        },
        {
          path: PermissionsCreateRoutes.pathname,
          element: (
            <Authorized
              component={<CreatePermission />}
              permission={"add_permission"}
            />
          ),
        },
        {
          path: PermissionsUpdateRoutes.pathname,
          element: (
            <Authorized
              component={<UpdatePermission />}
              permission={"edit_permission"}
            />
          ),
        },
        {
          path: RolesRoutes.pathname,
          element: (
            <Authorized component={<Roles />} permission={"view_roles"} />
          ),
        },
        {
          path: RolesCreateRoutes.pathname,
          element: (
            <Authorized component={<FormRole />} permission={"add_role"} />
          ),
        },
        {
          path: RolesUpdateRoutes.pathname,
          element: (
            <Authorized
              component={<FormRole isEdit />}
              permission={"edit_role"}
            />
          ),
        },
        { path: CompaniesRoutes.pathname, element: <Companies /> },
        {
          path: UsersRoutes.pathname,
          element: (
            <Authorized component={<Users />} permission={"view_users"} />
          ),
        },
        {
          path: UsersCreateRoutes.pathname,
          element: (
            <Authorized component={<FormUser />} permission={"add_user"} />
          ),
        },
        {
          path: UsersUpdateRoutes.pathname,
          element: (
            <Authorized
              component={<FormUser isEdit />}
              permission={"edit_user"}
            />
          ),
        },
        { path: ProfileRoutes.pathname, element: <Profile /> },
      ],
    },
    { path: "/401", element: <Unauthorized /> },
    { path: "/auth", element: <AuthUser /> },
    { path: LoginRoutes.pathname, element: <LoginPage /> },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_startTransition: true,
      v7_fetcherPersist: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

export { Router };
